import { ApiCore, type AuthToken, type AuthUserCredentials } from 'afpnews-api'
import { handleFunctionsError, supabase } from './supabase'
import { useThrottleFn } from '@vueuse/core'
import router from '@/router'
import { useStorage, StorageSerializers } from '@vueuse/core'

const authWithCredentials = async (credentials: AuthUserCredentials) => {
  const { data: afpToken, error } = await supabase.functions.invoke(
    'authenticate',
    {
      body: credentials
    }
  )

  await handleFunctionsError(error)

  if (!afpToken) throw new Error('Authentication to AFP API failed')
  return { afpToken }
}

const refreshToken = useThrottleFn(
  async (token: AuthToken) => {
    const { data: afpToken, error } = await supabase.functions.invoke(
      'refresh-token',
      {
        body: token
      }
    )

    await handleFunctionsError(error)

    if (!afpToken) throw new Error('Refresh AFP Token failed')
    return { afpToken }
  },
  3000
)

class ModifiedApiCore extends ApiCore {
  async authenticate(credentials?: AuthUserCredentials) {
    if (credentials) {
      const { afpToken } = await authWithCredentials(credentials)
      this.token = afpToken as AuthToken
      this.emit('tokenChanged', this.token)
      return this.token
    } else if (this.token && !this.isTokenValid) {
      const { afpToken } = await refreshToken(this.token)
      this.token = afpToken as AuthToken
      this.emit('tokenChanged', this.token)
      return this.token
    } else if (this.token && this.isTokenValid) {
      return this.token
    }
    throw new Error('Authentication failed')
  }
}

const apicore = new ModifiedApiCore({
  baseUrl: import.meta.env.VITE_APICORE_URL
})

const storedToken = useStorage(
  'apicore-token',
  apicore.token || null,
  localStorage,
  { serializer: StorageSerializers.object }
)
apicore.token = storedToken.value
apicore.on('tokenChanged', newToken => {
  if (newToken) {
    storedToken.value = newToken
  } else {
    storedToken.value = null
    router.replace({ name: 'login' })
  }
})

export async function logout() {
  apicore.token = undefined
  apicore.emit('tokenChanged', undefined)
}

export default apicore
