<script setup lang="ts">
  import { ref, watch, type Ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useHead } from '@unhead/vue'
  import { useI18n } from 'vue-i18n'
  import apicore from '@/plugins/api'
  import {
    signInWithEmail,
    verifyEmailToken,
    isLoggedIn,
    session,
    signOut
  } from '@/plugins/supabase'
  import PinCodeInput from '@/components/PinCodeInput.vue'
  import { useStepper } from '@vueuse/core'

  const router = useRouter()
  const route = useRoute()
  const { t } = useI18n()

  const {
    steps,
    stepNames,
    index,
    current,
    next,
    previous,
    isFirst,
    isLast,
    goTo,
    goToNext,
    goToPrevious,
    goBackTo,
    isNext,
    isPrevious,
    isCurrent,
    isBefore,
    isAfter,
  } = useStepper([
    'ask-credentials',
    'verify-credentials',
    'ask-token',
    'verify-token'
  ])

  const loading = ref(false)
  const email = ref('')
  const password = ref('')
  const token = ref('')
  const syncCheckbox = ref(true)
  const authError: Ref<false | string> = ref(false)

  function redirectToDeck() {
    router.push(
      route.query.redirect
        ? { path: route.query.redirect as string }
        : { name: 'deck' }
    )
  }

  watch(token, newVal => {
    authError.value = false
    if (newVal.length == 6) {
      verifyToken()
    }
  })

  /**
   * Vérifie le jeton de vérification de l'email
   *
   * 1. Réinitialise le message d'erreur de jeton
   * 2. Vérifie le jeton de vérification de l'email
   * 3. Si le jeton est valide:
   *    - Redirige vers le tableau de bord
   * 4. En cas d'erreur:
   *    - Affiche le message d'erreur
   *    - Met à jour l'état d'erreur
   *    - Désactive l'indicateur de chargement
   */
  async function verifyToken() {
    try {
      goTo('verify-token')
      await verifyEmailToken(email.value, token.value)
      redirectToDeck()
    } catch (error) {
      goTo('ask-token')
      console.error(error)
      authError.value = (error as Error).message
    }
  }

  /**
   * Gère le processus de connexion de l'utilisateur auprès de l'API Core et de Supabase
   *
   * 1. Réinitialise le message d'erreur d'authentification
   * 2. Tente l'authentification à l'API Core avec l'email et mot de passe fournis
   * 3. Si l'option de synchronisation est désactivée et l'utilisateur était déjà connecté à Supabase:
   *    - Déconnecte l'utilisateur actuel
   * 4. Si l'option de synchronisation est désactivée OU si l'utilisateur est déjà connecté avec le même email:
   *    - Redirige vers le tableau de bord
   * 5. Sinon:
   *    - Lance l'authentification à Supabase
   * 6. En cas d'erreur:
   *    - Affiche le message d'erreur
   *    - Met à jour l'état d'erreur
   *    - Désactive l'indicateur de chargement
   */
  async function handleLogin() {
    try {
      goTo('verify-credentials')
      loading.value = true
      authError.value = false

      const username = email.value.toLowerCase()

      await apicore.authenticate({
        username,
        password: password.value
      })
      if (syncCheckbox.value == false && isLoggedIn.value) {
        await signOut()
      }
      if (
        syncCheckbox.value == false ||
        (isLoggedIn.value &&
          session.value &&
          session.value.user.email == username)
      ) {
        redirectToDeck()
      } else {
        goTo('ask-token')
        await signInWithEmail(username)
      }
    } catch (error) {
      goTo('ask-credentials')
      console.error(error)
      authError.value = (error as Error).message
      loading.value = false
    }
  }

  useHead({
    title: `Login | ${import.meta.env.VITE_APP_TITLE}`
  })
</script>

<template>
  <form
    @submit.prevent="handleLogin"
    class="flex h-full items-center justify-center"
  >
    <fieldset
      class="fieldset bg-base-200 border-base-300 rounded-box w-xs border p-4"
    >
      <legend class="fieldset-legend">{{ t('auth.login') }}</legend>

      <label class="fieldset-label">{{ t('auth.email') }}</label>
      <input
        v-model.trim="email"
        :placeholder="t('auth.email')"
        :aria-label="t('auth.email')"
        :disabled="current !== 'ask-credentials'"
        :class="{
          'input-error': authError
        }"
        type="email"
        class="input validator"
        autocomplete="email"
        required
      />
      <div class="validator-hint hidden">{{ t('auth.email-warning') }}</div>

      <label class="fieldset-label">{{ t('auth.password') }}</label>
      <input
        v-model.trim="password"
        :placeholder="t('auth.password')"
        :aria-label="t('auth.password')"
        :disabled="current !== 'ask-credentials'"
        :class="{
          'input-error': authError
        }"
        type="password"
        class="input"
        autocomplete="password"
        required
      />

      <label class="fieldset-label mt-2">
        <input
          type="checkbox"
          v-model="syncCheckbox"
          :disabled="current !== 'ask-credentials'"
          class="toggle"
        />
        {{ t('auth.sync-settings') }}
      </label>

      <PinCodeInput
        v-if="current == 'ask-token'"
        v-model="token"
        :explanation="t('auth.token')"
        :class="{
          'input-error': authError
        }"
        class="mt-2"
        required
      />

      <button
        aria-label="submit"
        class="btn btn-primary mt-4"
        type="submit"
        :disabled="loading"
      >
        <span
          v-if="loading"
          class="loading loading-spinner"
        ></span>
        {{ t('submit') }}
      </button>
    </fieldset>
  </form>
</template>
