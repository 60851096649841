// export const urlB64ToUint8Array = (base64String: string) => {
//   const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
//   const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

//   const rawData = window.atob(base64)
//   const outputArray = new Uint8Array(rawData.length)

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i)
//   }
//   return outputArray
// }

export const uniqueBy = <V>(arr: V[], fn: (item: V) => string) => {
  const identifiers = new Set()
  return arr.reduce<V[]>((acc, item) => {
    const identifier = fn(item)
    if (identifiers.has(identifier)) return acc
    identifiers.add(identifier)
    acc.push(item)
    return acc
  }, [])
}

/**
 * Déplace un élément dans un tableau
 * @param {Array} array - Le tableau à modifier
 * @param {number} fromIndex - L'index actuel de l'élément
 * @param {number|'left'|'right'} to - Nouvelle position ou direction ('left', 'right', ou index numérique)
 * @returns {Array} - Le tableau modifié
 */
export function moveArrayItem<T>(
  array: T[],
  fromIndex: number,
  to: number | 'left' | 'right'
): T[] {
  // Créer une copie du tableau
  const newArray = [...array]

  // Calculer l'index de destination
  let toIndex
  if (to === 'left') {
    toIndex = Math.max(0, fromIndex - 1)
  } else if (to === 'right') {
    toIndex = Math.min(array.length - 1, fromIndex + 1)
  } else if (typeof to === 'number') {
    toIndex = Math.max(0, Math.min(array.length - 1, to))
  } else {
    throw new Error("Direction invalide. Utilisez 'left', 'right' ou un nombre")
  }

  // Retirer l'élément de sa position actuelle
  const [element] = newArray.splice(fromIndex, 1)

  // Insérer l'élément à sa nouvelle position
  newArray.splice(toIndex, 0, element)

  return newArray
}
