import { type Router } from 'vue-router'
import apiCore from '@/plugins/api'

export default function useCheckCredentials(router: Router) {
  router.beforeEach(async (to, _, next) => {
    if (to.meta.authProtected && !apiCore.isTokenValid) {
      return next({
        name: 'login',
        query: {
          redirect: to.path !== '/' ? to.fullPath : undefined
        },
        replace: true
      })
    }
    next()
  })
}
